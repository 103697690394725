import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c50f00f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")
  const _component_ion_img = _resolveComponent("ion-img")
  const _component_ion_card = _resolveComponent("ion-card")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_content = _resolveComponent("ion-content")

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showcaseList, (showcase) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  key: showcase.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(showcase.Title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (!showcase.IsVideo)
                          ? (_openBlock(), _createBlock(_component_ion_img, {
                              key: 0,
                              src: showcase.Img != '' ? showcase.Img : 'https://via.placeholder.com/200?text=CTE'
                            }, null, 8, ["src"]))
                          : _createCommentVNode("", true),
                        (showcase.IsVideo)
                          ? (_openBlock(), _createElementBlock("iframe", {
                              key: 1,
                              width: "100%",
                              height: "250",
                              src: showcase.URL,
                              frameborder: "0",
                              allow: "autoplay; encrypted-media",
                              allowfullscreen: ""
                            }, null, 8, _hoisted_1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}