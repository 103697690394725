import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4186952"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ItemFolder = _resolveComponent("ItemFolder")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_title = _resolveComponent("ion-title")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_modal = _resolveComponent("ion-modal")

  return (_openBlock(), _createBlock(_component_ion_content, { class: "main-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-justify-content-start" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folders, (folder) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  size: "2",
                  offset: "0",
                  onClick: () => {_ctx.displayFolder = folder},
                  key: folder.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ItemFolder, { folder: folder }, null, 8, ["folder"])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        onIonModalDidDismiss: _cache[0] || (_cache[0] = () => {_ctx.displayFolder = null}),
        "is-open": _ctx.displayFolder != null
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, { style: {"margin-top":"10px","margin-bottom":"10px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.displayFolder.Subject), 1)
                ]),
                _: 1
              }),
              _createElementVNode("iframe", {
                src: _ctx.drivePrefix + _ctx.displayFolder.GoogleURL + _ctx.drivePostfix,
                style: {"width":"100%","height":"700px","border":"0"}
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open"])
    ]),
    _: 1
  }))
}