import { getFirestore, collection, getDocs, getDoc, setDoc, doc, where, query, updateDoc, deleteDoc, addDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { initializeApp } from "firebase/app";
import {saveAs} from 'file-saver'

// const firebaseConfig = {
//     apiKey: "AIzaSyB67CX9yunc2ZVGvzSYy_ewW4Ku6fJ2nl8",
//     authDomain: "toledo-cte-86227.firebaseapp.com",
//     projectId: "toledo-cte-86227",
//     storageBucket: "toledo-cte-86227.appspot.com",
//     messagingSenderId: "127317004244",
//     appId: "1:127317004244:web:9900904cea06a7c5e41041"
//   };
  
const storageURL = "https://firebasestorage.googleapis.com/v0/b/";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCsY69fYNiXlmRpn8jMfQjSRFeSS2qHvpA",
    authDomain: "lcsd-cte.firebaseapp.com",
    projectId: "lcsd-cte",
    storageBucket: "lcsd-cte.appspot.com",
    messagingSenderId: "684191086892",
    appId: "1:684191086892:web:9036ec3d28cd863818d489"
  };

initializeApp(firebaseConfig);

const db = getFirestore();

export async function getUsers () {
    const snapshot = await getDocs(collection(db, "Users"));
    return snapshot;
}

export async function getUser (user, token) {
    const snapshot = await getDoc(doc(db, "Users", user.email));
    if (snapshot.exists()) {
        return snapshot.data();
    } else {
        const name = user.displayName.split(' ');
        await setDoc(doc(db, "Users", user.email), {
            first_name: name[0], 
            last_name: name[1],
            email: user.email,
            priority: 0,
            access_token: token
        })
        const newUser = await getDoc(doc(db, "Users", user.email));
        return newUser.data();
    }
}
export async function deleteShowcase(showcase) {
    const ref = doc(db, "Showcase", showcase.ID);
    await deleteDoc(ref);
}

export async function deleteRepo(repo) {
    const ref = doc(db, "Folder", repo.ID);
    await deleteDoc(ref)
}

export async function deleteLink (link) {
    const ref = doc(db, "Links", link.ID);
    await deleteDoc(ref);
}

export async function addShowcase(showcase) {
    if (showcase.Img.name) {
        const fileName = showcase.Img.name;
        const storage = getStorage();
        const backgroundRef = ref(storage, fileName);
        await uploadBytes(backgroundRef, showcase.Img);
        showcase.Img =  storageURL + "lcsd-cte.appspot.com" + "/o/" + fileName + "?alt=media";
    }
    if (showcase.URL) {
        const embedlink = showcase.URL.split('v=')[1];
        showcase.URL = "https://www.youtube.com/embed/" + embedlink;
    }
    const showcaseRef = doc(db, "Showcase", showcase.ID);
    await setDoc(showcaseRef, {
        Title: showcase.Title,
        Img: showcase.Img,
        URL: showcase.URL,
        IsVideo: showcase.IsVideo
    })
}

export async function addRepo(repo) {
    if (repo.BackgroundURL) {
        if (repo.BackgroundURL.name) {
            const fileName = repo.BackgroundURL.name;
            const storage = getStorage();
            const backgroundRef = ref(storage, fileName);
            await uploadBytes(backgroundRef, repo.BackgroundURL);
            repo.BackgroundURL =  storageURL + "lcsd-cte.appspot.com" + "/o/" + fileName + "?alt=media";
        }
    }
    const gdrive = repo.GoogleURL.split('/');
    repo.GoogleURL =  gdrive[gdrive.length - 1].split('?')[0]
    const repoRef = doc(db, "Folder", repo.ID);
    await setDoc(repoRef, {
        Subject: repo.Subject,
        GoogleURL: repo.GoogleURL,
        StudentView: repo.StudentView,
        BackgroundURL: repo.BackgroundURL
    })
}

export async function deleteAdmin(email) {
    const ref = doc(db, "Users", email);
    await updateDoc (ref, {
        priority: 0
    })
}

export async function addAdmin(email) {
    const ref = doc(db, "Users", email);
    await updateDoc (ref, {
        priority: 1
    })
    // try catch, return error if no user has been registered yet with that email
}

export async function addLink(link) {
    if (link.Img.name) {
        const fileName = link.Img.name;
        const storage = getStorage();
        const backgroundRef = ref(storage, fileName);
        await uploadBytes(backgroundRef, link.Img);
        link.Img =  storageURL + "lcsd-cte.appspot.com" + "/o/" + fileName + "?alt=media";
    }
    const linkRef = doc(db, "Links", link.ID);
    await setDoc (linkRef, {
        Title: link.Title,
        URL: link.URL,
        Img: link.Img
    })
}

export async function getRepos () {
    const snapshot = await getDocs(collection(db, "Folder"));
    return snapshot;
}

export async function getShowcases () {
    const snapshot = await getDocs(collection(db, "Showcase"))
    return snapshot;
}

export async function backupAll () {
    const backup = [];
    const adminSnapshot = await getDocs(collection(db, "Users"));
    const repoSnapshot = await getDocs(collection(db, "Folder"));
    const showcaseSnapshot = await getDocs(collection(db, "Showcase"));
    const linkSnapshot = await getDocs(collection(db, "Links"));

    const repoBackup = [];
    repoSnapshot.forEach(repo => {
        const data = repo.data();
        data["Key"] = data.Subject;
        repoBackup.push(data);
    })
    backup.push({"Folder": repoBackup});
    const adminBackup = [];
    adminSnapshot.forEach(admin => {
        const data = admin.data();
        data["Key"] = data.email;
        adminBackup.push(data);
    })
    backup.push({"Users": adminBackup});
    const showcaseBackup = [];
    showcaseSnapshot.forEach(showcase => {
        const data = showcase.data();
        data["Key"] = data.Title;
        showcaseBackup.push(data);
    })
    backup.push({"Showcase": showcaseBackup});
    const linkBackup = [];
    linkSnapshot.forEach(link => {
        const data = link.data();
        data["Key"] = data.Title;
        linkBackup.push(data);
    })
    backup.push({"Links": linkBackup});
    const date = new Date();
    var file = new File([JSON.stringify(backup)],"backup_"+date.toISOString()+".json", {type: "application/json;charset=utf-8"});
    saveAs(file);
}

async function deleteAllInCollection(key) {
    const docs = await getDocs(collection(db, key));
    docs.forEach(element => {
        const docRef = doc(db, key, element.id);
        deleteDoc(docRef);
    });
}

export async function importAll (file) {
    let text = await file.text();
    const backup = JSON.parse(text);
    backup.forEach(entry => {
        const key = Object.keys(entry)[0];
        //Delete collection
        deleteAllInCollection(key.toString());
        entry[key].forEach(obj => {
            addDoc(collection(db, key), obj);
        })
    })
}

export async function getAdmins () {
    const snapshot = await getDocs(query(collection(db, "Users"), where("priority", "==", 1)));
    return snapshot;
}

export async function getLinks () {
    const snapshot = await getDocs(collection(db, "Links"));
    return snapshot;
}

