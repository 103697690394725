import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_router_outlet = _resolveComponent("ion-router-outlet");
    const _component_ion_app = _resolveComponent("ion-app");
    return (_openBlock(), _createBlock(_component_ion_app, null, {
        default: _withCtx(() => [
            _createVNode(_component_ion_router_outlet)
        ]),
        _: 1
    }));
}
