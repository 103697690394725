import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29cef59a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-text-wrap card-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_img = _resolveComponent("ion-img")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_card = _resolveComponent("ion-card")

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[0] || (_cache[0] = () => {_ctx.displayFolder = !_ctx.displayFolder;})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    src: _ctx.folder.BackgroundURL != '' ? _ctx.folder.BackgroundURL : 'https://via.placeholder.com/250'
                  }, null, 8, ["src"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.folder.Subject), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}