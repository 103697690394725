import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")
  const _component_ion_img = _resolveComponent("ion-img")
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")
  const _component_ion_card = _resolveComponent("ion-card")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_content = _resolveComponent("ion-content")

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkList, (link) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  key: link.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, {
                      href: link.URL
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(link.Title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_img, {
                          src: link.Img
                        }, null, 8, ["src"]),
                        _createVNode(_component_ion_card_subtitle, { style: {"margin":"auto","text-align":"center"} }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(link.URL), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["href"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}